/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming'; // Plus imports for other components in your app.
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core(); // define a real custom palette (using http://mcg.mbitson.com)
$primary-color: (
    50 : #e7e7e7,
    100 : #c4c4c4,
    200 : #9c9c9c,
    300 : #747474,
    400 : #575757,
    500 : #393939,
    600 : #333333,
    700 : #2c2c2c,
    800 : #242424,
    900 : #171717,
    A100 : #ef7171,
    A200 : #ea4343,
    A400 : #fa0000,
    A700 : #e00000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$secondary-color: (
    50 : #fff4e7,
    100 : #ffe3c2,
    200 : #ffd19a,
    300 : #ffbe72,
    400 : #ffb053,
    500 : #ffa235,
    600 : #ff9a30,
    700 : #ff9028,
    800 : #ff8622,
    900 : #ff7516,
    A100 : #ffffff,
    A200 : #fffdfc,
    A400 : #ffddc9,
    A700 : #ffcdaf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$custom-primary: mat-palette($primary-color);
$custom-accent: mat-palette($secondary-color); // The warn palette is optional (defaults to red).
$custom-warn: mat-palette($mat-red); // Create the theme object (a Sass map containing all of the palettes).
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn); // Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($custom-theme);

.background-primary {
  background: mat-color($custom-primary);
}

.color-primary {
  color: mat-color($custom-primary);
}

.background-accent {
  background: mat-color($custom-accent);
}

.warning-message {
  background: mat-color($custom-warn);
}

.third-color {
  color: #f0284a;
}

.third-background {
  background: black;
}